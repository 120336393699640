import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import TitledTextBox from '../../../components/TitledFormTextBox/TitledFormTextBox';
import { ExtraTopMarginSmallButton } from '../../Styles';
import { SignUpUserRequest } from '../../../../api/user';
import { SignUpBox, SignUpBoxRow } from './Styles';

interface SignUpProps {
  handleSignUp: UseFormHandleSubmit<SignUpUserRequest>;
  registerSignUp: UseFormRegister<SignUpUserRequest>;
  onSignUp: (data: SignUpUserRequest) => void;
}

const SignUp = ({ handleSignUp, registerSignUp, onSignUp }: SignUpProps) => {
  return (
    <form onSubmit={handleSignUp(onSignUp)}>
      <SignUpBox>
        <SignUpBoxRow>
          <TitledTextBox
            title="First Name"
            label="firstName"
            register={registerSignUp}
            required
            white
          />
          <TitledTextBox
            title="Last Name"
            label="lastName"
            register={registerSignUp}
            required
            white
          />
        </SignUpBoxRow>
        <SignUpBoxRow>
          <TitledTextBox
            title="Email"
            label="email"
            register={registerSignUp}
            required
            white
          />
          <TitledTextBox
            title="Password"
            label="password"
            password
            register={registerSignUp}
            required
            white
          />
        </SignUpBoxRow>
        <TitledTextBox
          title="Invite Code"
          label="inviteCode"
          register={registerSignUp}
          required
          white
        />
        <ExtraTopMarginSmallButton title="Sign Up" transparent />
      </SignUpBox>
    </form>
  );
};

export default SignUp;
