import {
  LandingContainer,
  LandingPageTitle,
  LandingPageDetails,
  TopLandingPageRow,
  ExtraLargeIcon,
  TopLandingPageRowButtonContainer,
  MiddleLandingPageRowContainer,
  BottomLandingPageRowContainer,
  JoinTheWaitlistButton,
  LandingPageFormContainer,
} from './Styles';
import { useForm } from 'react-hook-form';
import {
  SignInUserRequest,
  SignUpUserRequest,
  signInUser,
  signUpUser,
} from '../../api/user';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../features/user/userSlice';
import logo from './../../assets/whitelogo.png';
import LargeButton from '../components/LargeButton/LargeButton';
import { useState } from 'react';
import SignUp from './components/SignUp/SignUp';
import SignIn from './components/SignIn/SignIn';
import TitledFormTextBox from '../components/TitledFormTextBox/TitledFormTextBox';
import { z } from 'zod';
import { addWaitlistUser } from '../../api/waitlist';

const waitlistUserRequestSchema = z.object({ email: z.string().email() });
type WaitlistUserRequest = z.infer<typeof waitlistUserRequestSchema>;

const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(selectUser);
  const [form, setForm] = useState<string>('');
  const { register: registerSignIn, handleSubmit: handleSignIn } =
    useForm<SignInUserRequest>();
  const { register: registerSignUp, handleSubmit: handleSignUp } =
    useForm<SignUpUserRequest>();
  const {
    register: waitlistRegister,
    handleSubmit: handleWaitlist,
    reset: waitlistReset,
  } = useForm<WaitlistUserRequest>();

  if (isAuthenticated) {
    navigate('/my-network');
  }

  const onSignUp = (data: SignUpUserRequest) => {
    dispatch(signUpUser(data)).then(({ payload }) => {
      if (payload) {
        navigate('/my-network');
      } else {
        alert(
          'There was a problem during sign up. Please double check your information.',
        );
      }
    });
  };

  const onSignIn = (data: SignInUserRequest) => {
    dispatch(signInUser(data)).then(({ payload }) => {
      if (payload) {
        navigate('/my-network');
      } else {
        alert(
          'There was a problem during sign in. Please double check your information.',
        );
      }
    });
  };

  const onWaitlistSubmission = async (data: WaitlistUserRequest) => {
    const response = await addWaitlistUser(data.email);
    if (response) {
      alert('You have been added to the waitlist!');
      waitlistReset();
    } else {
      alert(
        'There was a problem adding you to the waitlist. You may already be on the waitlist or there may be another issue. Please try again later.',
      );
    }
  };

  return (
    <LandingContainer>
      <TopLandingPageRow>
        <ExtraLargeIcon src={logo} alt="Intruencer Logo" large />
        <TopLandingPageRowButtonContainer>
          {form !== '' && (
            <LargeButton
              title="Join the waitlist"
              onClick={() => setForm('')}
            />
          )}
          {form !== 'signUp' && (
            <LargeButton
              title="I have an invite code"
              onClick={() => setForm('signUp')}
            />
          )}
          {form !== 'signIn' && (
            <LargeButton title="Login" onClick={() => setForm('signIn')} />
          )}
        </TopLandingPageRowButtonContainer>
      </TopLandingPageRow>
      <MiddleLandingPageRowContainer>
        <LandingPageTitle>Intruencer</LandingPageTitle>
        <LandingPageDetails>
          The professional network navigation tool for Chicago’s startup
          ecosystem.
        </LandingPageDetails>
        {form === 'signIn' ? (
          <SignIn
            handleSignIn={handleSignIn}
            onSignIn={onSignIn}
            registerSignIn={registerSignIn}
          />
        ) : form === 'signUp' ? (
          <SignUp
            handleSignUp={handleSignUp}
            onSignUp={onSignUp}
            registerSignUp={registerSignUp}
          />
        ) : (
          <form onSubmit={handleWaitlist(onWaitlistSubmission)}>
            <LandingPageFormContainer>
              <TitledFormTextBox
                placeholder="Your Email Address"
                label="email"
                register={waitlistRegister}
              />
              <JoinTheWaitlistButton>Join the waitlist</JoinTheWaitlistButton>
            </LandingPageFormContainer>
          </form>
        )}
      </MiddleLandingPageRowContainer>
      <BottomLandingPageRowContainer></BottomLandingPageRowContainer>
    </LandingContainer>
  );
};

export default Landing;
