import { z } from 'zod';

export const IntroRequestSchema = z.object({
  id: z.string(),
  requesterPersonId: z.string(),
  requesteePersonId: z.string(),
  introTargetPersonId: z.string(),
  accepted: z.boolean(),
});

export type IntroRequest = z.infer<typeof IntroRequestSchema>;

export const IntroRequestsSchema = z.array(IntroRequestSchema);

export type IntroRequests = z.infer<typeof IntroRequestsSchema>;
