import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '../features/user/userSlice';
import { connectionsSlice } from '../features/connections/connectionsSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../features/RootState';
import { invitesSlice } from '../features/invites/invitesSlice';
import { personsSlice } from '../features/persons/personsSlice';
import { introRequestsSlice } from '../features/introRequests/introRequestsSlice';

export const store = configureStore({
  reducer: {
    userState: userSlice.reducer,
    connectionsState: connectionsSlice.reducer,
    invitesState: invitesSlice.reducer,
    personsState: personsSlice.reducer,
    introRequestsState: introRequestsSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;

type DispatchFunction = () => AppDispatch;

export const useAppDispatch: DispatchFunction = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
