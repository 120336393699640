import { z } from 'zod';

export const formPersonSchema = z.object({
  name: z.string(),
  email: z.string(),
  profession: z.string().nullable(),
  company: z.string().nullable(),
  stage: z.string().nullable(),
  industry: z.array(z.string()),
  linkedinUrl: z.string().nullable(),
});

export type FormPerson = z.infer<typeof formPersonSchema>;

export const personSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  profession: z.string().nullable(),
  company: z.string().nullable(),
  stage: z.string().nullable(),
  industry: z.array(z.string()),
  linkedinUrl: z.string().nullable(),
});

export type Person = z.infer<typeof personSchema>;

export const PersonsSchema = z.array(personSchema);

export type Persons = z.infer<typeof PersonsSchema>;
