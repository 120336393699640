export const industryValuesArray = [
  {
    value: 'ai&ml',
    label: 'AI & ML',
  },
  {
    value: 'b2bsoftware',
    label: 'B2B Software',
  },
  {
    value: 'consumer',
    label: 'Consumer',
  },
  {
    value: 'developertools&infrastructuresoftware',
    label: 'Developer Tools & Infrastructure Software',
  },
  {
    value: 'fintech',
    label: 'FinTech',
  },
  {
    value: 'hardware',
    label: 'Hardware',
  },
  {
    value: 'healthcare&biotech',
    label: 'Healthcare & Biotech',
  },
  {
    value: 'manufacturinglogistics&supplychain',
    label: 'Manufacturing Logistics & Supply Chain',
  },
  {
    value: 'web3',
    label: 'Web3',
  },
  {
    value: 'advertising&marketing',
    label: 'Advertising & Marketing',
  },
  {
    value: 'aerospace',
    label: 'Aerospace',
  },
  {
    value: 'defense',
    label: 'Defense',
  },
  {
    value: 'govtech',
    label: 'GovTech',
  },
  {
    value: 'ar&vr',
    label: 'AR & VR',
  },
  {
    value: 'automation&productivitysoftware',
    label: 'Automation & Productivity Software',
  },
  {
    value: 'bankinglending',
    label: 'Banking - Lending',
  },
  {
    value: 'insurtech',
    label: 'InsurTech',
  },
  {
    value: 'cybersecurity',
    label: 'Cybersecurity',
  },
  {
    value: 'digitalhealth',
    label: 'Digital Health',
  },
  {
    value: 'education&training',
    label: 'Education & Training',
  },
  {
    value: 'energy&cleantech',
    label: 'Energy & Cleantech',
  },
  {
    value: 'futureofwork',
    label: 'Future of Work',
  },
  {
    value: 'gamingesportsentertainment',
    label: 'Gaming - Esports - Entertainment',
  },
  {
    value: 'legaltech',
    label: 'LegalTech',
  },
  {
    value: 'lowcodenocode',
    label: 'Low code - No code',
  },
  {
    value: 'personalfinance',
    label: 'Personal Finance',
  },
  {
    value: 'proptech',
    label: 'PropTech',
  },
  {
    value: 'salestech',
    label: 'SalesTech',
  },
  {
    value: 'transportationtravelhospitality',
    label: 'Transportation - Travel - Hospitality',
  },
  {
    value: 'edtech',
    label: 'EdTech',
  },
  {
    value: 'climatetech',
    label: 'ClimateTech',
  },
];

export const stagesArray = [
  {
    value: 'pre-seed',
    label: 'Pre-Seed',
  },
  {
    value: 'seed',
    label: 'Seed',
  },
  {
    value: 'series a',
    label: 'Series A',
  },
  {
    value: 'series b',
    label: 'Series B',
  },
  {
    value: 'series c',
    label: 'Series C',
  },
  {
    value: 'series d',
    label: 'Series D',
  },
  {
    value: 'series e',
    label: 'Series E',
  },
  {
    value: 'unknown',
    label: 'Unknown',
  },
];
