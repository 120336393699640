import styled from 'styled-components';

export const StyledLargeButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: rgba(3, 1, 50, 1);
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fonts.input};
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
