import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

interface ToastContainerProps {
  visible: boolean;
}

export const ToastContainer = styled.div<ToastContainerProps>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.secondary};
  padding: 10px;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fonts.primary};
  animation: ${({ visible }) => (visible ? fadeIn : fadeOut)} 0.5s forwards;
  z-index: 1001;
`;
