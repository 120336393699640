import axios from 'axios';
import { determineCorrectServerUrl } from './util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IntroRequestSchema,
  IntroRequestsSchema,
} from '../models/introRequest';
import { apiErrorHandler } from './apiErrorHandler';

export const getIntroRequestsByUserId = createAsyncThunk(
  'introRequests/getIntroRequestsByUserId',
  async (userId: string) => {
    try {
      const introRequests = (
        await axios.get(
          `${determineCorrectServerUrl()}/intro-requests/${userId}`,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedIntroRequests = IntroRequestsSchema.parse(introRequests);
      return validatedIntroRequests;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createIntroRequest = createAsyncThunk(
  'introRequests/createIntroRequest',
  async (introRequestData: any) => {
    try {
      const introRequest = (
        await axios.post(
          `${determineCorrectServerUrl()}/intro-requests`,
          introRequestData,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedIntroRequest = IntroRequestSchema.parse(introRequest);
      return validatedIntroRequest;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
