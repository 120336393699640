import styled from 'styled-components';

export const StyledSearchInputBox = styled.input`
  border-color: ${({ theme }) => theme.colors.borders.primary};
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  width: 350px;
  min-height: 35px;
  padding: 5px;
  font-size: ${({ theme }) => theme.fonts.input};
`;
