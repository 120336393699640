import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import TitledFormTextBox from '../TitledFormTextBox/TitledFormTextBox';
import {
  BottomButtonContainer,
  StyledCreateConnectionModalContainer,
  StyledCreateConnectionModalRow,
  TopMarginSmallButton,
} from './Styles';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  CreateConnectionRequest,
  createConnection,
  editConnection,
} from '../../../api/connection';

import 'draft-js/dist/Draft.css';
import { FormPerson } from '../../../models/person';
import SearchInputBox from '../SearchInputBox/SearchInputBox';
import { MaxHeightCellContainer } from '../MakeIntroModal/Styles';
import { FirstLegendCell, Legend, LegendCell } from '../../MyNetwork/Styles';
import ConnectionContainer, {
  getStageValue,
} from '../PersonContainer/PersonContainer';
import { selectConnections } from '../../../features/connections/connectionsSlice';
import { selectPersonById } from '../../../features/persons/personsSlice';
import { industryValuesArray } from '../../../constants';

interface PersonModalProps {
  isOpen: boolean;
  selectedPersonId?: string;
  onClose: () => void;
  showToast: (message: string) => void;
}

export const getIndustryValueAsFormattedArray = (industryArray: string[]) => {
  return industryArray
    .map((industry) => {
      const foundIndustry = industryValuesArray.find(
        (item) => item.value === industry,
      );
      return foundIndustry ? foundIndustry.label : null;
    })
    .filter((label): label is string => label !== null);
};

const PersonModal = ({
  isOpen,
  selectedPersonId,
  onClose,
  showToast,
}: PersonModalProps) => {
  const dispatch = useAppDispatch();
  const connections = useAppSelector(selectConnections);
  const selectedPerson = useAppSelector((state) =>
    selectPersonById(state, selectedPersonId),
  );
  const [editMode, setEditMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { register, setValue, handleSubmit, reset, watch } = useForm<
    CreateConnectionRequest & FormPerson
  >({
    defaultValues: {
      name: '',
      email: '',
      profession: null,
      company: null,
      stage: null,
      industry: [],
      linkedinUrl: '',
    },
  });
  const isEditing = selectedPerson && editMode;
  const isCreating = selectedPerson === undefined && !editMode;

  const clearValues = () => {
    onClose();
    reset();
  };

  const onSubmit = (data: CreateConnectionRequest) => {
    if (isEditing) {
      dispatch(
        editConnection({
          ...data,
          id: selectedPerson.id,
        }),
      ).then((response) => {
        if (response.payload) {
          showToast('Connection edited successfully');
          clearValues();
        } else {
          showToast('Error editing connection. Please try again.');
        }
      });
    } else if (isCreating) {
      dispatch(createConnection({ ...data })).then((response) => {
        if (response.payload) {
          showToast('Connection created successfully');
          clearValues();
        } else {
          showToast('Error creating connection. Please try again.');
        }
      });
    }
  };

  useEffect(() => {
    if (selectedPerson) {
      const { firstName, lastName, email } = selectedPerson;
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('email', email);

      if (selectedPerson) {
        setValue(
          'name',
          selectedPerson.firstName + ' ' + selectedPerson.lastName,
        );
        setValue('email', selectedPerson.email);
        setValue('profession', selectedPerson.profession);
        setValue('company', selectedPerson.company);
        setValue('stage', getStageValue(selectedPerson.stage));
        setValue(
          'industry',
          getIndustryValueAsFormattedArray(selectedPerson.industry),
        );
        setValue('linkedinUrl', selectedPerson.linkedinUrl);
      }
    }
    return () => {
      reset();
      setEditMode(false);
    };
  }, [selectedPerson, setValue, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => clearValues()}
      width={'75%'}
      maxHeight="80%"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledCreateConnectionModalContainer>
          <StyledCreateConnectionModalRow>
            <TitledFormTextBox
              title="Name"
              label="name"
              register={register}
              required
              readOnly={!(isEditing || isCreating)}
            />
            <TitledFormTextBox
              title="Email"
              label="email"
              register={register}
              required
              readOnly={!(isEditing || isCreating)}
            />
          </StyledCreateConnectionModalRow>
          <StyledCreateConnectionModalRow>
            <TitledFormTextBox
              title="Company"
              label="company"
              register={register}
              required
              readOnly={!(isEditing || isCreating)}
            />
            <TitledFormTextBox
              title="Industry"
              label="industry"
              register={register}
              required
              readOnly={!(isEditing || isCreating)}
            />
          </StyledCreateConnectionModalRow>
          <StyledCreateConnectionModalRow>
            <TitledFormTextBox
              title="Stage"
              label="stage"
              register={register}
              required
              readOnly={!(isEditing || isCreating)}
            />
            <TitledFormTextBox
              title="LinkedIn"
              label="linkedinUrl"
              register={register}
              required
              readOnly={!(isEditing || isCreating)}
              onClick={() => {
                window.open(watch('linkedinUrl') ?? undefined, '_blank');
              }}
              clickable
            />
          </StyledCreateConnectionModalRow>
          {/* <SummaryTextArea title="Summary" value={watch('summary') || ''} /> */}
        </StyledCreateConnectionModalContainer>
        {/* <SearchInputBox
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        /> */}
        {/* <MaxHeightCellContainer>
          <Legend>
            <FirstLegendCell>Name</FirstLegendCell>
            <LegendCell></LegendCell>
            <LegendCell>Role, Company</LegendCell>
            <LegendCell>Industry</LegendCell>
            <LegendCell>Email</LegendCell>
            <LegendCell>Stage</LegendCell>
          </Legend>
          {connections &&
            connections
              .filter((connection) => connection.archived === false)
              .filter((connection) => {
                if (searchValue === '') {
                  return true;
                }
                const fullName =
                  `${connection.firstName} ${connection.lastName}`.toLowerCase();
                return (
                  fullName.includes(searchValue.toLowerCase()) ||
                  connection.firstName
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase()) ||
                  connection.lastName
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase())
                );
              })
              .map((connection) => (
                <ConnectionContainer
                  key={connection.id}
                  name={connection.firstName + ' ' + connection.lastName}
                  email={connection.email}
                  personId={connection.personId}
                  onClick={() => {
                    // setNewIntroRecipient(connection);
                  }}
                />
              ))}
        </MaxHeightCellContainer> */}
        <BottomButtonContainer>
          <TopMarginSmallButton
            title={editMode ? 'Save' : selectedPerson ? 'Close' : 'Create'}
            onClick={onClose}
          />
        </BottomButtonContainer>
      </form>
    </Modal>
  );
};

export default PersonModal;
