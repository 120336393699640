import { MouseEvent } from 'react';
import { StyledSmallButton } from './Styles';

interface SmallButtonProps {
  title: string;
  onClick?: (() => void) | ((e: MouseEvent) => void);
  disabled?: boolean;
  className?: string;
  noSubmit?: boolean;
  transparent?: boolean;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
}
const SmallButton = ({
  title,
  onClick,
  disabled = false,
  className,
  noSubmit,
  transparent = false,
  width,
  marginTop,
  marginBottom,
}: SmallButtonProps) => {
  return (
    <StyledSmallButton
      type={noSubmit ? 'button' : 'submit'}
      onClick={onClick}
      disabled={disabled}
      className={className}
      transparent={transparent}
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {title}
    </StyledSmallButton>
  );
};

export default SmallButton;
