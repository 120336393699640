import { useState } from 'react';

const useToast = () => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');

  const showToast = (msg: string, duration = 3000) => {
    setMessage(msg);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, duration);
  };

  return { visible, message, showToast };
};

export default useToast;
