import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import {
  Required,
  StyledTextBox,
  StyledTitle,
  StyledTitledTextBoxContainer,
} from './Styles';

interface TitledFormTextBoxProps<TFormValues extends FieldValues> {
  title?: string;
  label: Path<TFormValues>;
  register: UseFormRegister<TFormValues>;
  placeholder?: string;
  password?: boolean;
  required?: boolean;
  large?: boolean;
  readOnly?: boolean;
  white?: boolean;
  onClick?: () => void;
  clickable?: boolean;
}

const TitledFormTextBox = <TFormValues extends FieldValues>({
  title,
  label,
  register,
  placeholder,
  password,
  required,
  large,
  readOnly = false,
  white = false,
  onClick,
  clickable,
}: TitledFormTextBoxProps<TFormValues>) => {
  return (
    <StyledTitledTextBoxContainer onClick={onClick} clickable={clickable}>
      {title ? (
        <StyledTitle white={white}>
          {title} {required ? <Required>*</Required> : null}
        </StyledTitle>
      ) : null}
      <StyledTextBox
        placeholder={placeholder}
        type={password ? 'password' : 'text'}
        large={large}
        {...register(label, {
          required,
        })}
        readOnly={readOnly}
      />
    </StyledTitledTextBoxContainer>
  );
};

export default TitledFormTextBox;
