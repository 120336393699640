import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { apiErrorHandler } from './apiErrorHandler';
import { determineCorrectServerUrl } from './util';
import axios from 'axios';

const GetInvitesByUserIdRequestSchema = z.string();

const CreateInviteRequestSchema = z.object({
  inviteeEmail: z.string(),
  code: z.string(),
  expiresAt: z.string(),
  userId: z.string(),
});

export type GetInvitesByUserIdRequest = z.infer<
  typeof GetInvitesByUserIdRequestSchema
>;

export type CreateInviteRequest = z.infer<typeof CreateInviteRequestSchema>;

export const getInvitesByUserId = createAsyncThunk(
  'invites/getInvitesByUserId',
  async (userId: GetInvitesByUserIdRequest) => {
    try {
      const validatedUserId = GetInvitesByUserIdRequestSchema.parse(userId);

      const invites = (
        await axios.get(
          `${determineCorrectServerUrl()}/invites/${validatedUserId}`,
          {
            withCredentials: true,
          },
        )
      ).data;

      return invites;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createInvite = createAsyncThunk(
  'invites/createInvite',
  async (request: CreateInviteRequest) => {
    try {
      const validatedRequest = CreateInviteRequestSchema.parse(request);
      const newInvite = (
        await axios.post(
          `${determineCorrectServerUrl()}/invites`,
          validatedRequest,
          {
            withCredentials: true,
          },
        )
      ).data;

      return newInvite;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const deleteInvite = createAsyncThunk(
  'invites/deleteInvite',
  async (inviteId: string) => {
    try {
      await axios.delete(`${determineCorrectServerUrl()}/invites/${inviteId}`, {
        withCredentials: true,
      });

      return inviteId;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
