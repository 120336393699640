import { StyledPageBody } from './Styles';

interface PageBodyProps {
  children: React.ReactNode;
}

const PageBody = ({ children }: PageBodyProps) => {
  return <StyledPageBody>{children}</StyledPageBody>;
};

export default PageBody;
