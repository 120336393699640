import styled from 'styled-components';
import SmallButton from '../SmallButton/SmallButton';

export const StyledCreateConnectionModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 90vh;
  overflow: auto;
`;

export const StyledConnectionModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCreateConnectionModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: row;
`;

export const ExpandPrompt = styled.div`
  align-self: center;
  font-size: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TopMarginSmallButton = styled(SmallButton)`
  margin-top: 10px;
`;
