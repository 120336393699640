import styled from 'styled-components';

interface StyledSmallButtonProps {
  disabled: boolean;
  transparent: boolean;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
}

export const StyledSmallButton = styled.button<StyledSmallButtonProps>`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.transparent
        ? 'transparent'
        : props.theme.colors.primary};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.text.disabled
      : props.theme.colors.text.primary};
  border: none;
  border-radius: 5px;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 30px;
  font-size: ${({ theme }) => theme.fonts.input};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
