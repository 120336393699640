import { z } from 'zod';

export const ConnectionSchema = z.object({
  id: z.string(),
  userId: z.string(),
  archived: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  personId: z.string().nullable(),
});

export type Connection = z.infer<typeof ConnectionSchema>;

export const ConnectionsSchema = z.array(ConnectionSchema);

export type Connections = z.infer<typeof ConnectionsSchema>;
