import axios from 'axios';
import { determineCorrectServerUrl } from './util';
import { PersonsSchema } from '../models/person';
import { apiErrorHandler } from './apiErrorHandler';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPersons = createAsyncThunk('persons/getPersons', async () => {
  try {
    const persons = (
      await axios.get(`${determineCorrectServerUrl()}/persons`, {
        withCredentials: true,
      })
    ).data;

    const validatedPersons = PersonsSchema.parse(persons);
    return validatedPersons;
  } catch (error) {
    apiErrorHandler(error);
  }
});

export const getPersonsByUserId = createAsyncThunk(
  'persons/getPersonsByUserId',
  async (userId: string) => {
    try {
      const connections = (
        await axios.get(`${determineCorrectServerUrl()}/persons/${userId}`, {
          withCredentials: true,
        })
      ).data;

      const validatedConnections = PersonsSchema.parse(connections);
      return validatedConnections;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
