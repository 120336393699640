import PageContainer from '../components/PageContainer/PageContainer';
import {
  CellContainer,
  FirstLegendCell,
  Legend,
  LegendCell,
} from './../MyNetwork/Styles';
import { EventHandler, MouseEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getConnectionsByUserId } from '../../api/connection';
import { selectUser } from '../../features/user/userSlice';
import InviteModal from '../components/InviteModal/InviteModal';
import { getPersons } from '../../api/person';
import useToast from '../../hooks/useToast';
import Toast from '../../components/Toast/Toast';
import MakeIntroModal from '../components/MakeIntroModal/MakeIntroModal';
import OnboardingModal from '../components/OnboardingModal/OnboardingModal';
import SearchInputBox from '../components/SearchInputBox/SearchInputBox';
import { DiscoverPageBodyContainer } from './Styles';
import {
  selectPersons,
  selectUserPersonObject,
} from '../../features/persons/personsSlice';
import { Person } from '../../models/person';
import PersonContainer from '../components/PersonContainer/PersonContainer';
import PersonModal from '../components/PersonModal/PersonModal';
import {
  createIntroRequest,
  getIntroRequestsByUserId,
} from '../../api/introRequest';

const Discover = () => {
  const dispatch = useAppDispatch();
  // const connections = useAppSelector(selectConnections);
  const userPerson = useAppSelector(selectUserPersonObject);
  const persons = useAppSelector(selectPersons);
  const { id, userHasCompletedOnboarding } = useAppSelector(selectUser);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [createPersonModalOpen, setCreatePersonModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [makeIntroModalOpen, setMakeIntroModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person>();
  const [searchValue, setSearchValue] = useState('');
  const { visible, message, showToast } = useToast();

  useEffect(() => {
    dispatch(getConnectionsByUserId(id));
    dispatch(getPersons());
    dispatch(getIntroRequestsByUserId(id));
    // setOnboardingModalOpen(!userHasCompletedOnboarding); // put back later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const handleClickPerson = (person: Person) => {
    setCreatePersonModalOpen(true);
    setSelectedPerson(person);
  };

  const handleClickIntroButton = (event: MouseEvent, personId: string) => {
    event.stopPropagation();
    dispatch(
      createIntroRequest({
        requesterPersonId: userPerson?.id,
        requesteePersonId: '77e468c6-fb0a-4d20-8e73-347686204193',
        introTargetPersonId: personId,
      }),
    );
  };

  return (
    <PageContainer>
      <DiscoverPageBodyContainer>
        <SearchInputBox
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <CellContainer>
          <Legend>
            <FirstLegendCell>Name</FirstLegendCell>
            <LegendCell></LegendCell>
            <LegendCell>Role, Company</LegendCell>
            <LegendCell>Industry</LegendCell>
            <LegendCell>Stage</LegendCell>
            <LegendCell>Intro</LegendCell>
          </Legend>
          {persons &&
            persons
              .filter((person) => {
                if (searchValue === '') {
                  return true;
                }
                const fullName =
                  `${person.firstName} ${person.lastName}`.toLowerCase();
                return (
                  fullName.includes(searchValue.toLowerCase()) ||
                  person.firstName
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase()) ||
                  person.lastName
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase())
                );
              })
              .map((person) => (
                <PersonContainer
                  key={person.id}
                  name={person.firstName + ' ' + person.lastName}
                  email={person.email}
                  personId={person.id}
                  onClick={() => handleClickPerson(person)}
                  onClickIntroButton={(event: MouseEvent) =>
                    handleClickIntroButton(event, person.id)
                  }
                />
              ))}
        </CellContainer>
      </DiscoverPageBodyContainer>
      <Toast visible={visible} message={message} />
      {/* <OnboardingModal
        isOpen={onboardingModalOpen}
        onClose={() => setOnboardingModalOpen(false)}
      /> */}
      <PersonModal
        isOpen={createPersonModalOpen}
        selectedPersonId={selectedPerson?.id}
        onClose={() => {
          setCreatePersonModalOpen(false);
          setSelectedPerson(undefined);
        }}
        showToast={showToast}
      />
      {/* <InviteModal
        isOpen={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      <MakeIntroModal
        isOpen={makeIntroModalOpen}
        onClose={() => setMakeIntroModalOpen(false)}
      /> */}
    </PageContainer>
  );
};

export default Discover;
