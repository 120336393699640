import { createSlice } from '@reduxjs/toolkit';
import { InitialUserState, RootState, Status } from '../RootState';
import {
  logOutUser,
  signInUser,
  signUpUser,
  userCompletedOnboardingStatus,
  validateRefreshToken,
} from '../../api/user';

const initialState: InitialUserState = {
  user: {
    isAuthenticated: false,
    id: '',
    createdAt: '',
    userHasCompletedOnboarding: true,
    personId: '',
  },
  status: Status.IDLE,
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInReducer: (state, action) => {
      return action.payload;
    },
    signOutReducer: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.user = action.payload;
      }
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.user = action.payload;
      }
    });
    builder.addCase(validateRefreshToken.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.user = action.payload;
      }
    });
    builder.addCase(validateRefreshToken.pending, (state) => {
      state.status = Status.LOADING;
    });
    builder.addCase(logOutUser.fulfilled, (state) => {
      state.status = Status.SUCCEEDED;
      state.user = initialState.user;
    });
    builder.addCase(
      userCompletedOnboardingStatus.fulfilled,
      (state, action) => {
        if (action) {
          state.user.userHasCompletedOnboarding = true;
        }
      },
    );
  },
});

export const selectUser = (state: RootState) => state.userState.user;

export const selectValidateRefreshTokenStatus = (state: RootState) =>
  state.userState.status;

export const { signInReducer, signOutReducer } = userSlice.actions;
