import styled from 'styled-components';
import { device } from '../../../themes/breakpoints';

export const StyledPageBody = styled.div`
  display: flex;
  margin-left: 16vw;
  margin-top: 10px;

  @media all and (max-width: 950px) {
    margin-left: 165px;
  }

  @media ${device.sm} {
    margin-top: 10vh;
    margin-left: 0;
    padding: 10px;
    flex-direction: column;
    gap: 15px;
  }
`;
