import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    primary: '#1f2968',
    secondary: 'white',
    third: '#eeeeee',
    disabled: '#eeeeee',
    text: {
      primary: 'white',
      secondary: '#6e6e6e',
      disabled: '#a5a5a5',
    },
    borders: {
      primary: '#d2d2d2',
    },
    containers: {
      hover: {
        primary: '#eeeeee',
        secondary: '#a5a5a5',
      },
    },
  },
  fonts: {
    input: '12px',
    primary: '12px',
    header: '14px',
  },
};

interface GeneralThemeProviderProps {
  children: React.ReactNode;
}

const GeneralThemeProvider = ({ children }: GeneralThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GeneralThemeProvider;
