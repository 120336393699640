import { StyledIcon } from './Styles';

interface IconProps {
  src: string;
  alt: string;
  onClick?: () => void;
  large?: boolean;
  className?: string;
}

const Icon = ({ src, alt, onClick, large, className }: IconProps) => {
  return (
    <StyledIcon
      onClick={onClick}
      src={src}
      alt={alt}
      large={large}
      className={className}
    />
  );
};

export default Icon;
