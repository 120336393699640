import { createSlice } from '@reduxjs/toolkit';
import { InitialPersonsState, RootState, Status } from '../RootState';
import { getPersons, getPersonsByUserId } from '../../api/person';

const initialState: InitialPersonsState = {
  persons: [],
  status: Status.IDLE,
  error: null,
};

export const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPersons.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.persons = action.payload;
      }
    });
    builder.addCase(getPersonsByUserId.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.persons = action.payload;
      }
    });
  },
});

export const selectPersons = (state: RootState) => state.personsState.persons;

export const selectPersonById = (
  state: RootState,
  personId: string | null | undefined,
) => state.personsState.persons.find((person) => person.id === personId);

export const selectUserPersonObject = (state: RootState) =>
  state.personsState.persons.find(
    (person) => person.id === state.userState.user.personId,
  );

export const selectIfIntroRequestToPersonIsPending = (
  state: RootState,
  personId: string | undefined,
  userPersonId: string | undefined,
) => {
  if (!personId || !userPersonId) {
    return false;
  }

  return state.introRequestsState.introRequests.some(
    (introRequest) =>
      introRequest.introTargetPersonId === personId &&
      introRequest.requesterPersonId === userPersonId,
  );
};
