import styled from 'styled-components';
import SmallButton from '../components/SmallButton/SmallButton';
import Icon from '../components/Icon/Icon';
import { device } from '../../themes/breakpoints';

export const LandingContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0;
  font-weight: 300;
  line-height: 1.5;
  margin: -8px;
  overflow: auto;
  background-image: radial-gradient(
    circle 382px at 50% 50.2%,
    rgba(73, 76, 212, 1) 0.1%,
    rgba(3, 1, 50, 1) 100.2%
  );
`;

export const TopLandingPageRow = styled.div`
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;

  @media (${device.sm}) {
    flex-direction: column-reverse;
    gap: 35px;
    margin-top: 10px;
  }
`;

export const MiddleLandingPageRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  flex: 1;
`;

export const BottomLandingPageRowContainer = styled.div`
  flex: 0.3;
`;

export const ExtraLargeIcon = styled(Icon)`
  height: 50px;
  width: 50px;
`;

export const TopLandingPageRowButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const LandingPageDetails = styled.div`
  max-width: 700px;
  width: 90%;
  text-align: center;
  color: white;
  letter-spacing: 1px;
`;

export const LandingPageFormContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: clamp(200px, 50vw, 500px);
  margin-top: 30px;

  @media (${device.md}) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: clamp(300px, 100vw, 350px);
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const LandingPageTitle = styled.div`
  font-size: 3em;
  letter-spacing: 4px;
  margin: 0 0 20px 0;
  max-width: 700px;
  color: white;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: clamp(300px, 100vw, 350px);
  border: none;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
`;

export const ExtraTopMarginSmallButton = styled(SmallButton)`
  margin-top: 10px;
`;

export const JoinTheWaitlistButton = styled.button`
  background-color: white;
  height: 40px;
  width: clamp(130px, 20vw, 150px);
  border: none;
  padding: 0px 10px;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    cursor: pointer;
  }
`;
