import axios from 'axios';
import { determineCorrectServerUrl } from './util';
import { apiErrorHandler } from './apiErrorHandler';
import { z } from 'zod';

const AdminCreatePersonRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  profession: z.string(),
  company: z.string(),
  stage: z.string(),
  industry: z.array(z.string()),
});

export type AdminCreatePersonRequest = z.infer<
  typeof AdminCreatePersonRequestSchema
>;

export const adminCreatePerson = async (data: AdminCreatePersonRequest) => {
  try {
    const connections = (
      await axios.post(
        `${determineCorrectServerUrl()}/admin/create-person`,
        data,
        {
          withCredentials: true,
        },
      )
    ).data;

    return connections;
  } catch (error) {
    apiErrorHandler(error);
  }
};
