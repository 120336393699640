import { createSlice } from '@reduxjs/toolkit';
import {
  archiveConnection,
  bulkCreateConnections,
  createConnection,
  editConnection,
  getConnectionsByUserId,
  importConnectionsFromGoogle,
} from '../../api/connection';
import { InitialConnectionsState, RootState, Status } from '../RootState';

const initialState: InitialConnectionsState = {
  connections: [],
  status: Status.IDLE,
  error: null,
};

export const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectionsByUserId.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.connections = action.payload;
      }
    });
    builder.addCase(createConnection.fulfilled, (state, action) => {
      if (action.payload) {
        state.connections.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(bulkCreateConnections.fulfilled, (state, action) => {
      if (action.payload) {
        state.connections = [...state.connections, ...action.payload];
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(editConnection.fulfilled, (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const connectionIndex = state.connections.findIndex(
          (connection) => connection.id === id,
        );
        if (connectionIndex !== -1) {
          state.connections[connectionIndex] = action.payload;
          state.status = Status.SUCCEEDED;
        }
      }
    });
    builder.addCase(archiveConnection.fulfilled, (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const connectionIndex = state.connections.findIndex(
          (connection) => connection.id === id,
        );
        if (connectionIndex !== -1) {
          state.connections[connectionIndex] = action.payload;
          state.status = Status.SUCCEEDED;
        }
      }
    });
    builder.addCase(importConnectionsFromGoogle.fulfilled, (state, action) => {
      if (action.payload) {
        state.connections = [...state.connections, ...action.payload];
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectConnections = (state: RootState) =>
  state.connectionsState.connections;
