import { StyledSearchInputBox } from './Styles';

interface SearchInputBoxProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  className?: string;
}

const SearchInputBox = ({
  searchValue,
  setSearchValue,
  className,
}: SearchInputBoxProps) => {
  return (
    <StyledSearchInputBox
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      className={className}
      placeholder="Search..."
    />
  );
};

export default SearchInputBox;
