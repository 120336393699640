import { Backdrop, ModalBox } from './Styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string;
  maxHeight?: string;
}

const Modal = ({ isOpen, onClose, children, width, maxHeight }: ModalProps) => {
  if (!isOpen) return null;

  return (
    <Backdrop onClick={onClose}>
      <ModalBox
        onClick={(e) => e.stopPropagation()}
        width={width}
        maxHeight={maxHeight}
      >
        {children}
      </ModalBox>
    </Backdrop>
  );
};

export default Modal;
