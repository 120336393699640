import styled from 'styled-components';

export const AdminPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AdminFormContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AdminFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
