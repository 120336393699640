import { useNavigate } from 'react-router-dom';
import {
  SidebarContainer,
  NavList,
  NavItem,
  LogOutButton,
  NavText,
} from './Styles';
import { useAppDispatch } from '../../store/store';
import { logOutUser } from '../../api/user';
import Icon from '../../pages/components/Icon/Icon';
import search from './../../assets/icons/search.png';
import recommend from './../../assets/icons/recommend.png';

const NavigationSidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(logOutUser());
  };

  return (
    <SidebarContainer>
      <NavList>
        <NavItem onClick={() => navigate('/discover')}>
          <Icon src={search} alt="Discover Icon" /> <NavText>Discover</NavText>
        </NavItem>
        <NavItem onClick={() => navigate('/my-network')}>
          <Icon src={recommend} alt="My Network Icon" />{' '}
          <NavText>My Network</NavText>
        </NavItem>
      </NavList>
      <LogOutButton onClick={() => logOut()}>Log Out</LogOutButton>
    </SidebarContainer>
  );
};

export default NavigationSidebar;
