import { createSlice } from '@reduxjs/toolkit';
import { InitialInvitesState, RootState, Status } from '../RootState';
import {
  createInvite,
  deleteInvite,
  getInvitesByUserId,
} from '../../api/invite';

const initialState: InitialInvitesState = {
  invites: [],
  status: Status.IDLE,
  error: null,
};

export const invitesSlice = createSlice({
  name: 'invites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvitesByUserId.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.invites = action.payload;
      }
    });
    builder.addCase(createInvite.fulfilled, (state, action) => {
      if (action.payload) {
        state.invites.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(deleteInvite.fulfilled, (state, action) => {
      if (action.payload) {
        state.invites = state.invites.filter(
          (invite) => invite.id !== action.payload,
        );
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectInvites = (state: RootState) => state.invitesState.invites;
