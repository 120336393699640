import styled from 'styled-components';

interface StyledInviteStatusProps {
  accepted: boolean;
  expired: boolean;
}

export const StyledInviteStatus = styled.div<StyledInviteStatusProps>`
  background-color: ${(props) =>
    props.accepted ? '#5DFF5D' : props.expired ? '#ffaaaa' : '#eeeeee'};
  padding: 5px 7px;
  margin: 0;
  color: ${(props) =>
    props.accepted ? 'green' : props.expired ? 'red' : 'grey'};
  font-size: ${(props) => props.theme.fonts.primary};
  border-radius: 5px;
  white-space: nowrap;
`;
