import { Persons } from '../models/person';
import { Connections } from '../models/connection';
import { Invites } from '../models/invite';
import { User } from '../models/user';
import { IntroRequests } from '../models/introRequest';

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface InitialUserState {
  user: User;
  status: Status;
  error: string | null;
}

export interface InitialConnectionsState {
  connections: Connections;
  status: Status;
  error: string | null;
}

export interface InitialInvitesState {
  invites: Invites;
  status: Status;
  error: string | null;
}

export interface InitialPersonsState {
  persons: Persons;
  status: Status;
  error: string | null;
}

export interface InitialIntroRequestsState {
  introRequests: IntroRequests;
  status: Status;
  error: string | null;
}

export interface RootState {
  userState: InitialUserState;
  connectionsState: InitialConnectionsState;
  invitesState: InitialInvitesState;
  personsState: InitialPersonsState;
  introRequestsState: InitialIntroRequestsState;
}
