import { selectIntroRequestsIncludingPersonObjects } from '../../../features/introRequests/introRequestsSlice';
import { useAppSelector } from '../../../store/store';
import PersonContainer from '../../components/PersonContainer/PersonContainer';
import { CellContainer, FirstLegendCell, Legend, LegendCell } from '../Styles';
import {
  IntroRequestInfoBoxContainer,
  IntroRequestsSentBoxContainer,
  IntroRequestsSentHeaderTitle,
  NoIntroRequestsSentMessage,
} from './Styles';

const IntroRequestsSentBox = () => {
  const introRequestsSent = useAppSelector(
    selectIntroRequestsIncludingPersonObjects,
  );
  return (
    <IntroRequestInfoBoxContainer>
      <IntroRequestsSentHeaderTitle>
        Intro Requests Sent
      </IntroRequestsSentHeaderTitle>
      <IntroRequestsSentBoxContainer>
        <CellContainer>
          <Legend>
            <FirstLegendCell>Name</FirstLegendCell>
            <LegendCell></LegendCell>
            <LegendCell>Role, Company</LegendCell>
            <LegendCell>Industry</LegendCell>
            <LegendCell>Stage</LegendCell>
            <LegendCell>Intro</LegendCell>
          </Legend>
          {introRequestsSent.length > 0 ? (
            introRequestsSent
              .filter((introRequest) => {
                if (introRequest.accepted === true) {
                  return false;
                }
                return true;
              })
              .map((introRequest) => (
                <PersonContainer
                  key={introRequest.id}
                  name={
                    introRequest.targetPerson?.firstName +
                    ' ' +
                    introRequest.targetPerson?.lastName
                  }
                  email={introRequest.targetPerson?.email || ''} // Might want to fix this later?
                  personId={introRequest.targetPerson?.id || ''} // Might want to fix this later?
                  onClick={() => {}}
                />
              ))
          ) : (
            <NoIntroRequestsSentMessage>
              You haven't sent any introduction requests at this time. Request
              an introduction on the Discover page.
            </NoIntroRequestsSentMessage>
          )}
        </CellContainer>
      </IntroRequestsSentBoxContainer>
    </IntroRequestInfoBoxContainer>
  );
};

export default IntroRequestsSentBox;
