import PageContainer from '../components/PageContainer/PageContainer';
import {
  ButtonContainer,
  CellContainer,
  FirstLegendCell,
  MyNetworkBodyContainer,
  MyNetworkHeader,
  Legend,
  LegendCell,
  IntroInfoContainer,
  ConnectionsTitle,
} from './Styles';
import CreateConnectionModal from '../components/PersonModal/PersonModal';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getConnectionsByUserId } from '../../api/connection';
import { selectUser } from '../../features/user/userSlice';
import { selectConnections } from '../../features/connections/connectionsSlice';
import { Connection } from '../../models/connection';
import ConnectionContainer from '../components/PersonContainer/PersonContainer';
import SmallButton from '../components/SmallButton/SmallButton';
import InviteModal from '../components/InviteModal/InviteModal';
import { getPersons, getPersonsByUserId } from '../../api/person';
import useToast from '../../hooks/useToast';
import Toast from '../../components/Toast/Toast';
import MakeIntroModal from '../components/MakeIntroModal/MakeIntroModal';
import OnboardingModal from '../components/OnboardingModal/OnboardingModal';
import SearchInputBox from '../components/SearchInputBox/SearchInputBox';
import IntroRequestsBox from './IntroRequestsSentBox/IntroRequestsSentBox';
import { NoIntroRequestsSentMessage } from './IntroRequestsSentBox/Styles';
import { selectIntroRequests } from '../../features/introRequests/introRequestsSlice';
import { getIntroRequestsByUserId } from '../../api/introRequest';

const MyNetwork = () => {
  const dispatch = useAppDispatch();
  const connections = useAppSelector(selectConnections);
  const introRequests = useAppSelector(selectIntroRequests);
  const { id, userHasCompletedOnboarding } = useAppSelector(selectUser);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [createConnectionModalOpen, setCreateConnectionModalOpen] =
    useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [makeIntroModalOpen, setMakeIntroModalOpen] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState<Connection>();
  const [searchValue, setSearchValue] = useState('');
  const { visible, message, showToast } = useToast();

  useEffect(() => {
    dispatch(getConnectionsByUserId(id));
    dispatch(getPersons());
    dispatch(getIntroRequestsByUserId(id));
    // setOnboardingModalOpen(!userHasCompletedOnboarding); put back
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const handleClickConnection = (connection: Connection) => {
    setCreateConnectionModalOpen(true);
    setSelectedConnection(connection);
  };

  return (
    <PageContainer>
      <MyNetworkBodyContainer>
        <MyNetworkHeader>
          {/* <ButtonContainer>
            <SmallButton
              title="Create a Connection"
              onClick={() => setCreateConnectionModalOpen(true)}
              width="150px"
              marginBottom="20px"
            />
          </ButtonContainer> */}
          {/* <SmallButton
            title="Invite Friends"
            onClick={() => {
              setInviteModalOpen(true);
            }}
          /> */}
        </MyNetworkHeader>
        {/* <SmallButton
          title="Make Intro"
          onClick={() => setMakeIntroModalOpen(true)}
          marginBottom="10px"
        /> */}
        <IntroInfoContainer>
          <IntroRequestsBox />
        </IntroInfoContainer>
        <ConnectionsTitle>Connections</ConnectionsTitle>
        <SearchInputBox
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <CellContainer>
          <Legend>
            <FirstLegendCell>Name</FirstLegendCell>
            <LegendCell></LegendCell>
            <LegendCell>Role, Company</LegendCell>
            <LegendCell>Industry</LegendCell>
            <LegendCell>Stage</LegendCell>
            <LegendCell>Intro</LegendCell>
          </Legend>
          {connections.length > 0 ? (
            connections
              .filter((connection) => connection.archived === false)
              .filter((connection) => {
                if (searchValue === '') {
                  return true;
                }
                const fullName =
                  `${connection.firstName} ${connection.lastName}`.toLowerCase();
                return (
                  fullName.includes(searchValue.toLowerCase()) ||
                  connection.firstName
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase()) ||
                  connection.lastName
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase())
                );
              })
              .map((connection) => (
                <ConnectionContainer
                  key={connection.id}
                  name={connection.firstName + ' ' + connection.lastName}
                  email={connection.email}
                  personId={connection.personId}
                  onClick={() => handleClickConnection(connection)}
                />
              ))
          ) : (
            <NoIntroRequestsSentMessage>
              You have no connections at this time.
            </NoIntroRequestsSentMessage>
          )}
        </CellContainer>
      </MyNetworkBodyContainer>
      <Toast visible={visible} message={message} />
      {/* <OnboardingModal
        isOpen={onboardingModalOpen}
        onClose={() => setOnboardingModalOpen(false)}
      /> */}
      {/* <CreateConnectionModal
        isOpen={createConnectionModalOpen}
        selectedConnection={selectedConnection}
        onClose={() => {
          setCreateConnectionModalOpen(false);
          setSelectedConnection(undefined);
        }}
        showToast={showToast}
      /> */}
      {/* <InviteModal
        isOpen={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      <MakeIntroModal
        isOpen={makeIntroModalOpen}
        onClose={() => setMakeIntroModalOpen(false)}
      /> */}
    </PageContainer>
  );
};

export default MyNetwork;
