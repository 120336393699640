import styled from 'styled-components';
import { Box } from '../../Styles';
import { device } from '../../../../themes/breakpoints';

export const SignUpBox = styled(Box)`
  width: 600px;

  @media (${device.sm}) {
    width: clamp(300px, 100vw, 350px);
  }
`;

export const SignUpBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

  @media (${device.sm}) {
    flex-direction: column;
  }
`;
