import styled from 'styled-components';

export const Cell = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.primary};
`;

export const ConnectionCell = styled(Cell)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const FirstConnectionCell = styled(ConnectionCell)`
  text-align: left;
`;

export const Content = styled.div`
  display: contents;
  font-size: ${(props) => props.theme.fonts.primary};
  &:hover {
    cursor: pointer;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 3px;
`;
