/*
This is temporary. I will add mobile at some point in the near future.
*/

interface DeviceTooSmallProps {
  children?: React.ReactNode;
}

export const DeviceTooSmall = ({ children }: DeviceTooSmallProps) => {
  const width = window.innerWidth;
  return (
    <>
      {width > 1000 ? (
        children
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '98vh',
          }}
        >
          <p
            style={{
              color: 'gray',
              fontSize: '14px',
            }}
          >
            Intruencer is currently only available on desktop. Please use a
            larger screen.
          </p>
        </div>
      )}
    </>
  );
};
