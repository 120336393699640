import { useCallback, useEffect } from 'react';
import { validateRefreshToken } from '../../api/user';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { selectUser } from '../../features/user/userSlice';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(selectUser);

  const checkForValidTokens = useCallback(async () => {
    if (!isAuthenticated) {
      await dispatch(validateRefreshToken());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    checkForValidTokens();
  }, [isAuthenticated, checkForValidTokens]);

  return <>{children}</>;
};

export default AuthProvider;
