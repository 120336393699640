import styled from 'styled-components';

interface StyledContainerProps {
  clickable?: boolean;
}

interface StyledTitleProps {
  white?: boolean;
}

export const StyledTitledTextBoxContainer = styled.div<StyledContainerProps>`
  width: 100%;

  :hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  }
`;

export const StyledTitle = styled.p<StyledTitleProps>`
  margin: 0;
  font-size: ${({ theme }) => theme.fonts.input};
  font-weight: bold;
  margin-bottom: 5px;
  color: ${(props) => props.white && 'white'};
`;

export const Required = styled.span`
  color: red;
`;

interface StyledTextBoxProps {
  large?: boolean;
  readOnly?: boolean;
}
export const StyledTextBox = styled.input<StyledTextBoxProps>`
  width: 100%;
  height: ${(props) => (props.large ? '100px' : '40px')};
  border-radius: 5px;
  border-color: ${({ theme }) => theme.colors.borders.primary};
  border-style: solid;
  border-width: 1px;
  padding-left: 5px;
  color: ${(props) =>
    props.readOnly ? props.theme.colors.text.secondary : 'black'};
`;
