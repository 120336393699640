import { Navigate } from 'react-router-dom';
import { Status } from '../../features/RootState';
import {
  selectUser,
  selectValidateRefreshTokenStatus,
} from '../../features/user/userSlice';
import { useAppSelector } from '../../store/store';

interface ProtectedRouteInterface {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteInterface) => {
  const { isAuthenticated } = useAppSelector(selectUser);
  const status = useAppSelector(selectValidateRefreshTokenStatus);

  if (status === Status.LOADING) {
    return <>{children}</>;
  }

  if (!isAuthenticated && status === Status.SUCCEEDED) {
    return <Navigate to={'/'} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
