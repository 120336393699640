import { ToastContainer } from './Styles';

interface ToastProps {
  message: string;
  visible: boolean;
}

const Toast = ({ message, visible }: ToastProps) => {
  return <ToastContainer visible={visible}>{message}</ToastContainer>;
};

export default Toast;
