import { isPast } from 'date-fns';
import { StyledInviteStatus } from './Styles';

interface InviteStatusProps {
  accepted: boolean;
  expiresAt?: string;
}

const InviteStatus = ({ accepted, expiresAt }: InviteStatusProps) => {
  const isExpired = expiresAt ? isPast(expiresAt) : false;

  return (
    <StyledInviteStatus accepted={accepted} expired={isExpired}>
      {accepted ? 'Connected' : isExpired ? 'Expired' : 'Intro Pending'}
    </StyledInviteStatus>
  );
};

export default InviteStatus;
