import { useForm } from 'react-hook-form';
import { AdminFormContainer, AdminFormRow, AdminPageContainer } from './Styles';
import TitledFormTextBox from '../components/TitledFormTextBox/TitledFormTextBox';
import SmallButton from '../components/SmallButton/SmallButton';
import { adminCreatePerson, AdminCreatePersonRequest } from '../../api/admin';
import { useState } from 'react';
import { industryValuesArray } from '../../constants';

const AdminPage = () => {
  const { register, handleSubmit, setValue, reset } =
    useForm<AdminCreatePersonRequest>();
  const [industryValue, setIndustryValue] = useState<string[]>();
  const onSubmit = async (data: AdminCreatePersonRequest) => {
    const result = await adminCreatePerson({
      ...data,
    });
    if (result) {
      reset();
    }
  };

  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value,
    );
    setIndustryValue(selectedValues);
    setValue('industry', selectedValues);
  };

  return (
    <AdminPageContainer>
      <h3>Create Person</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AdminFormContainer>
          <AdminFormRow>
            <TitledFormTextBox
              title="First Name"
              label="firstName"
              register={register}
              required
            />
            <TitledFormTextBox
              title="Last Name"
              label="lastName"
              register={register}
              required
            />
          </AdminFormRow>
          <AdminFormRow>
            <TitledFormTextBox
              title="Email"
              label="email"
              register={register}
              required
            />
            <TitledFormTextBox
              title="Profession"
              label="profession"
              register={register}
              required
            />
          </AdminFormRow>
          <AdminFormRow>
            <TitledFormTextBox
              title="Company / Firm"
              label="company"
              register={register}
              required
            />
            <TitledFormTextBox
              title="Stage / Stage They Invest"
              label="stage"
              register={register}
              required
            />
          </AdminFormRow>
          <AdminFormRow>
            <TitledFormTextBox
              title="Industry"
              label="industry"
              register={register}
              required
              readOnly
            />
            <select
              multiple
              value={industryValue}
              onChange={handleIndustryChange}
            >
              {industryValuesArray.map((object) => (
                <option key={object.value} value={object.value}>
                  {object.label}
                </option>
              ))}
            </select>
          </AdminFormRow>
          <SmallButton title="Submit" />
        </AdminFormContainer>
      </form>
    </AdminPageContainer>
  );
};

export default AdminPage;
