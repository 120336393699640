import { createSlice } from '@reduxjs/toolkit';
import { InitialIntroRequestsState, RootState, Status } from '../RootState';
import {
  createIntroRequest,
  getIntroRequestsByUserId,
} from '../../api/introRequest';

const initialState: InitialIntroRequestsState = {
  introRequests: [],
  status: Status.IDLE,
  error: null,
};

export const introRequestsSlice = createSlice({
  name: 'introRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIntroRequestsByUserId.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.introRequests = action.payload;
      }
    });
    builder.addCase(createIntroRequest.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.introRequests.push(action.payload);
      }
    });
  },
});

export const selectIntroRequests = (state: RootState) =>
  state.introRequestsState.introRequests;

export const selectIntroRequestsIncludingPersonObjects = (state: RootState) => {
  const introRequests = selectIntroRequests(state);
  const persons = state.personsState.persons;

  return introRequests.map((introRequest) => {
    const targetPerson = persons.find(
      (person) => person.id === introRequest.introTargetPersonId,
    );

    return {
      ...introRequest,
      targetPerson,
    };
  });
};

export const selectIsIntroRequestAccepted = (personId: string | undefined) => {
  return (state: RootState) => {
    if (!personId) {
      return false;
    }
    return state.introRequestsState.introRequests.some(
      (introRequest) =>
        introRequest.introTargetPersonId === personId && introRequest.accepted,
    );
  };
};
