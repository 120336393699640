import styled from 'styled-components';
import { Cell } from '../components/PersonContainer/Styles';

export const MyNetworkBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MyNetworkHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const IntroInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 30px;
`;

export const CellContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr) 0.1fr;
  font-size: 12px;
`;

export const Legend = styled.div`
  display: contents;
`;

export const LegendCell = styled(Cell)`
  font-weight: bold;
`;

export const FirstLegendCell = styled(LegendCell)`
  text-align: left;
`;

export const ConnectionsTitle = styled.div`
  font-size: ${(props) => props.theme.fonts.header};
  font-weight: bold;
  margin-bottom: 10px;
`;
