import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import AuthProvider from './components/AuthProvider/AuthProvider';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import GeneralThemeProvider from './themes/ThemeProvider';
import GlobalStyles from './themes/GlobalStyles';
import { Provider } from 'react-redux';
import { store } from './store/store';
import AdminPage from './pages/AdminPage/AdminPage';
import MyNetwork from './pages/MyNetwork/MyNetwork';
import Discover from './pages/Discover/Discover';
import { DeviceTooSmall } from './pages/DeviceTooSmall.tsx/DeviceTooSmall';

const App = () => {
  return (
    <Provider store={store}>
      <GeneralThemeProvider>
        <GlobalStyles />
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route
                path="/discover"
                element={
                  <DeviceTooSmall>
                    <ProtectedRoute>
                      <Discover />
                    </ProtectedRoute>
                  </DeviceTooSmall>
                }
              />
              <Route
                path="/my-network"
                element={
                  <DeviceTooSmall>
                    <ProtectedRoute>
                      <MyNetwork />
                    </ProtectedRoute>
                  </DeviceTooSmall>
                }
              />
              <Route
                path="/admin"
                element={
                  // <ProtectedRoute>
                  <AdminPage />
                  // </ProtectedRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </GeneralThemeProvider>
    </Provider>
  );
};

export default App;
