import axios from 'axios';
import { ConnectionSchema, ConnectionsSchema } from '../models/connection';
import { apiErrorHandler } from './apiErrorHandler';
import { determineCorrectServerUrl } from './util';
import { z } from 'zod';
import { createAsyncThunk } from '@reduxjs/toolkit';

const GetConnectionsByUserIdRequestSchema = z.string();

const CreateConnectionRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  profession: z.string().nullable(),
  company: z.string().nullable(),
  stage: z.string().nullable(),
  industry: z.array(z.string()),
});

const BulkCreateConnectionRequestSchema = z.array(
  CreateConnectionRequestSchema,
);

const EditConnectionRequestSchema = z.object({
  id: z.string(),
  ...CreateConnectionRequestSchema.shape,
});

const ArchiveConnectionRequestSchema = z.object({
  id: z.string(),
  archived: z.boolean(),
});

export type GetConnectionsByUserIdRequest = z.infer<
  typeof GetConnectionsByUserIdRequestSchema
>;

export type CreateConnectionRequest = z.infer<
  typeof CreateConnectionRequestSchema
>;

export type EditConnectionRequest = z.infer<typeof EditConnectionRequestSchema>;

export type ArchiveConnectionRequest = z.infer<
  typeof ArchiveConnectionRequestSchema
>;

export type BulkCreateConnectionsRequest = z.infer<
  typeof BulkCreateConnectionRequestSchema
>;

export const getConnectionsByUserId = createAsyncThunk(
  'connections/getConnectionsByUserId',
  async (userId: GetConnectionsByUserIdRequest) => {
    try {
      const connections = (
        await axios.get(
          `${determineCorrectServerUrl()}/connections/user/${userId}`,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedConnections = ConnectionsSchema.parse(connections);
      return validatedConnections;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createConnection = createAsyncThunk(
  'connections/createConnection',
  async (connection: CreateConnectionRequest) => {
    try {
      const newConnection = (
        await axios.post(
          `${determineCorrectServerUrl()}/connections`,
          connection,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedNewConnection = ConnectionSchema.parse(newConnection);
      return validatedNewConnection;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const bulkCreateConnections = createAsyncThunk(
  'connections/bulkCreateConnections',
  async (connections: BulkCreateConnectionsRequest) => {
    try {
      const newConnections = (
        await axios.post(
          `${determineCorrectServerUrl()}/connections/bulk`,
          connections,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedNewConnections = ConnectionsSchema.parse(newConnections);
      return validatedNewConnections;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editConnection = createAsyncThunk(
  'connections/editConnection',
  async (connection: EditConnectionRequest) => {
    try {
      const editedConnection = (
        await axios.patch(
          `${determineCorrectServerUrl()}/connections`,
          connection,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedEditedConnection =
        ConnectionSchema.parse(editedConnection);
      return validatedEditedConnection;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const archiveConnection = createAsyncThunk(
  'connections/archiveConnection',
  async (connectionData: ArchiveConnectionRequest) => {
    try {
      const archivedConnection = (
        await axios.patch(
          `${determineCorrectServerUrl()}/connections/archive/${connectionData.id}`,
          connectionData.archived,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedArchivedConnection =
        ConnectionSchema.parse(archivedConnection);
      return validatedArchivedConnection;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const importConnectionsFromGoogle = createAsyncThunk(
  'connections/importConnectionsFromGoogle',
  async (userId: string) => {
    try {
      const contacts = (
        await axios.get(
          `${determineCorrectServerUrl()}/connections/from-google/${userId}`,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedContacts = ConnectionsSchema.parse(contacts);

      return validatedContacts;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
