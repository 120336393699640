import styled from 'styled-components';

export const IntroRequestInfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
export const IntroRequestsSentBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  border: 1px solid ${(props) => props.theme.colors.borders.primary};
  border-radius: 5px;
  padding: 0px 10px 10px 10px;
  overflow: auto;
`;

export const IntroRequestsSentHeaderTitle = styled.div`
  font-size: ${(props) => props.theme.fonts.header};
  font-weight: bold;
`;

export const NoIntroRequestsSentMessage = styled.div`
  font-size: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.text.secondary};
  margin-top: 10px;
  white-space: nowrap;
`;
