import styled from 'styled-components';

interface StyledIconProps {
  large?: boolean;
}

export const StyledIcon = styled.img<StyledIconProps>`
  height: ${({ large }) => (large ? '30px' : '20px')};
  width: ${({ large }) => (large ? '30px' : '20px')};
  &:hover {
    cursor: pointer;
  }
`;
