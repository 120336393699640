import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body {
    *, *::before, *::after {
        box-sizing: border-box;
    }
    font-family: Sans-Serif;
}`;

export default GlobalStyles;
