import axios from 'axios';
import { determineCorrectServerUrl } from './util';
import { apiErrorHandler } from './apiErrorHandler';

export const addWaitlistUser = async (email: string) => {
  try {
    const waitlistUser = (
      await axios.post(
        `${determineCorrectServerUrl()}/waitlist`,
        { email },
        {
          withCredentials: true,
        },
      )
    ).data;

    return waitlistUser;
  } catch (error) {
    apiErrorHandler(error);
  }
};
