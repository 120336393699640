import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import TitledTextBox from '../../../components/TitledFormTextBox/TitledFormTextBox';
import { Box, ExtraTopMarginSmallButton } from '../../Styles';
import { SignInUserRequest } from '../../../../api/user';

interface SignInProps {
  onSignIn: (data: SignInUserRequest) => void;
  handleSignIn: UseFormHandleSubmit<SignInUserRequest>;
  registerSignIn: UseFormRegister<SignInUserRequest>;
}

const SignIn = ({ onSignIn, handleSignIn, registerSignIn }: SignInProps) => {
  return (
    <form onSubmit={handleSignIn(onSignIn)}>
      <Box>
        <TitledTextBox
          title="Email"
          label="email"
          register={registerSignIn}
          required
          white
        />
        <TitledTextBox
          title="Password"
          label="password"
          password
          register={registerSignIn}
          required
          white
        />
        <ExtraTopMarginSmallButton title="Sign In" transparent />
      </Box>
    </form>
  );
};

export default SignIn;
