import { StyledLargeButton } from './Styles';

interface LargeButtonProps {
  title: string;
  onClick: () => void;
}

const LargeButton = ({ title, onClick }: LargeButtonProps) => {
  return <StyledLargeButton onClick={onClick}>{title}</StyledLargeButton>;
};

export default LargeButton;
