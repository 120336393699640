import {
  ConnectionCell,
  Content,
  FirstConnectionCell,
  IconsContainer,
} from './Styles';
import Icon from '../Icon/Icon';
import twitter from './../../../assets/twitter.png';
import linkedin from './../../../assets/linkedin.png';
import { useAppSelector } from '../../../store/store';
import {
  selectIfIntroRequestToPersonIsPending,
  selectPersonById,
  selectUserPersonObject,
} from '../../../features/persons/personsSlice';
import SmallButton from '../SmallButton/SmallButton';
import InviteStatus from '../InviteModal/components/InviteStatus/InviteStatus';
import { selectIsIntroRequestAccepted } from '../../../features/introRequests/introRequestsSlice';
import { industryValuesArray, stagesArray } from '../../../constants';
import { MouseEvent } from 'react';

interface PersonProps {
  name: string;
  email: string;
  personId: string | null;
  onClick: () => void;
  onClickIntroButton?: (event: MouseEvent) => void;
}

export const getIndustryValueAsFormattedString = (industryArray: string[]) => {
  const maxLength = 35;
  const labels = industryArray
    .map((industry) => {
      const foundIndustry = industryValuesArray.find(
        (item) => item.value === industry,
      );
      return foundIndustry ? foundIndustry.label : null;
    })
    .filter((label) => label !== null);

  const result = labels.join(', ');

  if (result.length > maxLength) {
    return result.slice(0, maxLength - 3) + '...';
  }

  return result;
};

export const getStageValue = (stage: string | null) => {
  if (!stage) {
    return 'Unknown';
  }
  const values = stage
    .split(',')
    .map((stageValue) => stageValue.trim().toLowerCase());

  const labels = values.map((value) => {
    const stage = stagesArray.find((stage) => stage.value === value);
    return stage ? stage.label : value;
  });

  return labels.join(', ');
};

const PersonContainer = ({
  name,
  email,
  personId,
  onClick,
  onClickIntroButton,
}: PersonProps) => {
  const person = useAppSelector((state) => selectPersonById(state, personId));
  const userPerson = useAppSelector(selectUserPersonObject);
  const isIntroRequestPending = useAppSelector((state) =>
    selectIfIntroRequestToPersonIsPending(state, person?.id, userPerson?.id),
  );
  const isIntroRequestAccepted = useAppSelector(
    selectIsIntroRequestAccepted(person?.id),
  );

  const displayProfession = (profession: string) => {
    if (profession === 'vc') {
      return profession.toUpperCase();
    } else if (profession === 'founder') {
      return profession.charAt(0).toUpperCase() + profession.slice(1);
    } else {
      return profession;
    }
  };

  return (
    <Content onClick={onClick}>
      <FirstConnectionCell>{name}</FirstConnectionCell>
      <ConnectionCell></ConnectionCell>
      <ConnectionCell>
        <IconsContainer>
          {person?.linkedinUrl && (
            <a
              href={person?.linkedinUrl ? person?.linkedinUrl : undefined}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={linkedin} alt="LinkedIn Logo" />
            </a>
          )}
        </IconsContainer>
        {person?.profession && person.company
          ? displayProfession(person.profession) + ', ' + person.company
          : 'None, None'}
      </ConnectionCell>
      <ConnectionCell>
        {person?.industry
          ? getIndustryValueAsFormattedString(person.industry)
          : 'None'}
      </ConnectionCell>
      <ConnectionCell>
        {person?.stage ? getStageValue(person?.stage) : 'None'}
      </ConnectionCell>
      <ConnectionCell>
        {/* For this person, is there an existing IntroRequest. That's what we need to be looking for with this pending variable
        So, for this person, we need a selector that we can hit that will check to see if there is a IntroRequest object with
        this person's id as the intro target person as well as the requester id matching the user's person id*/}
        {isIntroRequestPending ? (
          <InviteStatus accepted={isIntroRequestAccepted} />
        ) : (
          <SmallButton onClick={onClickIntroButton} title="Request Intro" />
        )}

        {/* <IconsContainer>
          {person?.twitterUrl && (
            <a
              href={person.twitterUrl ? twitter : undefined}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={twitter} alt="Twitter Logo" />
            </a>
          )}
          {person?.linkedinUrl && (
            <a
              href={
                person?.linkedinUrl
                  ? person?.linkedinUrl
                  : undefined
              }
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={linkedin} alt="LinkedIn Logo" />
            </a>
          )}
        </IconsContainer> */}
      </ConnectionCell>
    </Content>
  );
};

export default PersonContainer;
