import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

interface ModalBoxProps {
  width: string | undefined;
  maxHeight?: string;
}

export const ModalBox = styled.div<ModalBoxProps>`
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  max-height: ${(props) => props.maxHeight || 'none'};
`;
